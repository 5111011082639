
<script type="text/javascript"></script>
<template>
	<div id="newsfeedpages" class = "pagefixedwidth">
		<TopBar simple hidedropdown>
			<template v-slot:title>
				Newsfeed
			</template>
		</TopBar>
		<div class = "main-wrapper">
			<newsfeedlist @opennewsletterforviewing = "opennewsletterfromfeed" :newsletters = "newsletters"></newsfeedlist>
		</div>
	</div>
</template>
<style  lang="scss" scoped>
@import '@/scss/abstracts/variable.scss';


</style>
<script>

	import {bus} from '@/main'
	import {TopBar} from '@/components/topbar'
	import {newsfeedlist} from './components'
	import Newslettersapi from '@/services/api/newsletters'
	
	
	export default{
		name:'newsfeedpages',
		props:{
			page:{
				type: Boolean,
				default: true
			}
		},
		data(){
			return{
				isshown: false,
				opennewsletterfromfeedmodal : false,
				token:"",
				newsletterid:"",
				acktivcockpitname:"",
				newsletters:[]

			}
		},

		components:{
			newsfeedlist, TopBar
		},

		computed:{

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },

		},

		watch:{

		},
		mounted(){
			$('#preloader').addClass('hide')
			

			this.token = localStorage.token
			
			this.loadNewsfeed()
			

			this.$nextTick(function(){
				document.title ='Newsfeed | Flowe'
			})

		},



		methods:{
			loadNewsfeed(){

				let self = this
                // console.log('user id',this.loggedinuser)
                //this.$store.state.user.userInfo._id,
                Newslettersapi.Getfeed( this.token, this.orgkey )
                .then( function(response){

                	if( response.data.ok ){
                		self.newsletters = response.data.newsletters
                		//console.log(response.data.newsletters)
                	}

                })
                .catch(error => console.log(error))
                .finally( () => {

                	this.shownewsfeed = true
                //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
            })

            },
           	 opennewsletterfromfeed( nid, cid ){
				//alert('openfromfeed'

				bus.$emit('onopennewsletterforviewing', nid, cid)
				
			},
			
			shownewsletter(newsletterid, autherid){
				this.$emit('shownewsletter',newsletterid, autherid)
			},
			onmodalhidden(){
				this.$emit('onmodalhidden')
			},
			onmodalhide(){
				// this.$router.push(this.$route.path)
			}
			

		}
	}

</script>